import tokenGovernance from '~img/token_governance.svg'
import tokenPayment from '~img/token_payment.svg'
import tokenMarketplace from '~img/token_marketplace.svg'
import tokenIncentives from '~img/token_incentives.svg'

export default {
  HERO: {
    title: 'Token',
    description: 'SCT token is based on the Solana-based token standard.',
  },
  ABOUT: [
    {
      title: 'Governance',
      description:
        'SolClout aims for a decentralized platform managed and owned by the token holders. Vote on protocol improvement proposals to better SolClout. ',
      image: tokenGovernance,
    },
    {
      title: 'Payment',
      description:
        'Send borderless payments with almost close-to-zero transaction fees powered by the Solana blockchain. SolClout is the most social way to send payments.',
      image: tokenPayment,
    },
    {
      title: 'Marketplace',
      description:
        'Purchase digital stickers and items from the marketplace using SCT token. Digital items purchased on the marketplace is available exclusive on the platform.',
      image: tokenMarketplace,
    },
    {
      title: 'Incentives',
      description:
        'For users contributing to the platform growth, SolClout has an algorithmic method to provide token incentives. Contribute and earn SCT token. ',
      image: tokenIncentives,
    },
  ],
}
