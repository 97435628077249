import * as React from 'react'

import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'

import { AboutBlock } from '~components/AboutBlock'

import DATA from './data'

export const Token = () => (
  <Layout>
    <SEO title={DATA.HERO.title} />
    <Hero {...DATA.HERO} modifier="token" />
    {DATA.ABOUT.map((block, index) => (
      <AboutBlock {...block} key={block.title} isRtl={index % 2} isFirst={index === 0} />
    ))}
  </Layout>
)
